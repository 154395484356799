<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">题库管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 150px;">题库名称:</span>
              <el-input v-model="retrievalData.bankName" type="text" size="small" placeholder="题库名称" clearable />
            </div>
            <div title="作业类别" class="searchboxItem ci-full">
              <span class="itemLabel">作业类别:</span>
              <el-select clearable size="small" v-model="retrievalData.workCategory" placeholder="请选择">
                <el-option v-for="item in workCategoryList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="open_add">新增题库</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="noAssignmentList">
              <span>暂无启用题库 ：</span>
              <span v-for="(item, index) in noAssignmentList" :key="index">
                {{ item.dictValue }}；
              </span>
            </div>
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="题库名称" align="left" show-overflow-tooltip prop="bankName" minWidth="100" />
              <el-table-column label="作业类别" align="left" show-overflow-tooltip prop="workCategory" minWidth="100">
                <template slot-scope="scope">
                  {{ scope.row.workCategory ? $setDictionary("PA_WORK_CATEGORY", scope.row.workCategory) : "" }}
                </template>
              </el-table-column>
              <el-table-column label="备注" align="left" show-overflow-tooltip prop="remark" minWidth="100" />
              <el-table-column label="创建时间" align="left" show-overflow-tooltip prop="createTime" minWidth="100" />
              <el-table-column label="状态" align="left" show-overflow-tooltip prop="enable" minWidth="80">
                <template slot-scope="scope">
                  <el-switch :width="20" v-model="scope.row.enable" active-color="#13ce66" @change="
                    (val) => {
                      handleStu(val, scope.row);
                    }
                  "></el-switch>
                  <span>{{ scope.row.enable ? "启用" : "禁用" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="140px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="to_info(scope.row)">详情</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="open_add(scope.row)">编辑</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="delete_data(scope.row)">删除</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 新增题库 - 弹框 -->
    <el-dialog title="新增题库" :visible.sync="addQuestionBankData.dialogVisible" width="500px" @close="closeAddEditStuden">
      <el-form ref="addQuestionBankData" label-width="100px" size="small" :rules="addQuestionBankDataRules"
        :model="addQuestionBankData">
        <el-form-item label="题库名称:" prop="bankName">
          <el-input type="text" v-model="addQuestionBankData.bankName" clearable placeholder="请输入题库名称" />
        </el-form-item>
        <el-form-item label="作业类别:" prop="workCategory">
          <el-select size="small" v-model="addQuestionBankData.workCategory" clearable placeholder="请选择作业类别">
            <el-option v-for="item in workCategoryList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input type="textarea" v-model="addQuestionBankData.remark" clearable placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="close_add" class="bgc-bv">取 消</el-button>
        <el-button size="small" @click="sure_add" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examinationAffairsManage_questionBank",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      retrievalData: {
        bankName: "", // 题库名称
        workCategory: "", // 作业类别
      },
      workCategoryList: [], // 作业类别 - 下拉数据
      noAssignmentList: [], // 无启用作业类别列表
      // 添加题库 - 弹框数据
      addQuestionBankData: {
        dialogVisible: false, // 弹框状态
        bankId: "", // 题库id
        bankName: "", // 题库名称
        workCategory: "", // 作业类别
        remark: "", // 备注
      },
      // 添加题库 - 弹框数据校验
      addQuestionBankDataRules: {
        bankName: [
          { required: true, message: "请输入题库名称", trigger: "blur" },
        ],
        workCategory: [
          { required: true, message: "请选择作业类别", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getDictionary();
    this.get_noAssignmentList();
  },
  watch: {},
  computed: {},
  mounted() { },
  methods: {
    // 获取 - 字典
    getDictionary() {
      // 作业类别
      let workCategory = this.$setDictionary("PA_WORK_CATEGORY", "list");
      for (const key in workCategory) {
        this.workCategoryList.push({
          value: key,
          label: workCategory[key],
        });
      }
    },
    // 获取 - 无启用作业类别列表
    get_noAssignmentList() {
      this.$post("/biz/bank/pa-question-bank/selectNoEnableWorkCategory", {}, 3000, true, 10)
        .then((res) => {
          this.noAssignmentList = res.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.retrievalData
      };
      this.doFetch({
        url: "/biz/bank/pa-question-bank/pageList",
        params,
        pageNum,
      }, true, 10);
    },
    // 修改启用状态
    handleStu(enable, row) {
      this.$post("/biz/bank/pa-question-bank/updateState", {
        bankId: row.bankId
      }, 3000, true, 10).then((res) => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.getData(this.pageNum);
        }
      })
        .catch((err) => {
          row.enable = !row.enable;
          return;
        });
    },
    // 新增题库 - 打开弹框
    open_add(row) {
      if (row) {
        this.addQuestionBankData = { ...this.addQuestionBankData, ...row };
      }
      this.addQuestionBankData.dialogVisible = true;
    },
    // 新增题库 - 关闭弹框
    close_add() {
      this.addQuestionBankData = this.$options.data().addQuestionBankData;
    },
    // 新增题库 - 弹框 - 确定
    sure_add() {
      this.$refs['addQuestionBankData'].validate((valid) => {
        if (valid) {
          let url = '';
          if (this.addQuestionBankData.bankId) {
            url = '/biz/bank/pa-question-bank/modify'
          } else {
            url = '/biz/bank/pa-question-bank/insert'
          }
          this.$post(url, this.addQuestionBankData, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功');
              this.close_add();
              this.getData(this.pageNum);
            })
            .catch((err) => {
              return;
            });
        }
      })
    },
    // 编辑题库
    to_info(row) {
      this.$router.push({
        path: "/practicalAssessmentSystem/examinationAffairsManage/questionBank_info",
        query: {
          bankId: row?.bankId ?? '', // 题库id
        }
      })
    },
    // 删除
    delete_data(row) {
      this.$post("/biz/bank/pa-question-bank/delete", {
        bankId: row.bankId
      }, 3000, true, 10)
        .then((res) => {
          this.$message.success('操作成功')
          this.getData(this.pageNum);
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.noAssignmentList {
  color: red;
  margin-bottom: 10px;
}
</style>